import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';

function App() {
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/php/getuserinfo.php');
      const result = await response.json();
      setUserInfo(result);
    };

    fetchData();
  }, []); // Empty dependency array means this runs once after the component mounts
  return (
    <div className="App">
      {JSON.stringify(userInfo)}
    </div>
  );
}

export default App;
